#references {
  background-color: var(--color_second-opacity-25);
}

.hero-sub {
  background-color: var(--color_second-opacity-90);
}

.quote-box.even {
  background-color: var(--color_second-opacity-25);
}

.quote-box.mid {
  background-color: var(--color_second-opacity-75);
}

.quote-box.odd {
  background-color: var(--color_seventh);
  color: var(--color_third);
}

.quote-box .container {
  position: relative;
}

.quote-box.odd .beforeLined:before {
  background-image: url("../img/line_invert.svg");
}

.quote-box p {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_5);
}

.quote-box .caption {
  font-size: var(--font-size_4);
}

@media (max-width: 768px) {
  .quote-box .caption {
    font-size: var(--font-size_8);
  }
}
