

/*************************** NAVIGATION ********************************/

@media (min-width: 992px) {
  nav#menu {
    position: absolute;
    right: 110px;
    top: 32px;
  }
}

@media (min-width: 1201px) {
  nav#menu {
    position: absolute;
    right: 160px;
    top: 32px;
  }
}

@media (min-width: 992px) {
  .topnav #menuItems {
    display: block !important;
  }
}

.drawer {
    display: none;
}

@media (max-width: 991px) {
  .drawer {
      display: block;
  }
}

/* Style the navigation menu */
@media (max-width: 991px) {
  .topnav {
    position: fixed;
    right: 0;
    top: 0;
  }
}


@media (max-width: 991px) {
  /* Hide the links inside the navigation menu (except for logo/home) */
  .topnav #menuItems {
    display: none;
  }
}

@media (max-width: 991px) {
  #menuItems {
    height: 100vh;
    width: 400px;
    padding: 128px 16px 0 16px;
    right: 0;
    top: 0;
    background-color: var(--color_third);
    position: relative;
    animation-name: nav_draw;
    animation-duration: .3s;
  }
}

@media (max-width: 767px) {
  #menuItems {
    width: 82vw;
  }
}

@keyframes nav_draw {
  from {
    right: -300px;
  }

  to {
    right: 0;
  }
}

/* Style navigation menu links */
.topnav .drawer a:hover {
  width: 58px;
  height: 72px;
  padding: 0;
  text-decoration: none;
  font-size: 32px;
  font-weight: 400;
  text-align: center;
  line-height: 72px;
  border-bottom: 1px solid transparent;
  display: block;
  text-decoration: none;
  border: 0;
}

@media (max-width: 991px) {
  .topnav .drawer a {
    width: 58px;
    height: 72px;
    padding: 0;
    text-decoration: none;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    line-height: 72px;
    border-bottom: 1px solid transparent;
    display: block;
  }
}


.topnav a, .topnav a:hover {
  display: inline-block;
  text-decoration: none;
  color: var(--color_main);
  padding: 8px 16px;
}

@media (max-width: 1200px) {
  .topnav a, .topnav a:hover {
    padding: 6px;
  }
}

@media (max-width: 991px) {
  .topnav a, .topnav a:hover {
    width: 100%;
  }
}

.topnav a[aria-current]:after {
  content: "";
  display: block;
  height: 12px;
  width: auto;
  margin: -10px 0 0 0;
  background: var(--color_second-opacity-50);
}


/* Style the hamburger menu */

@media (max-width: 991px) {
  .topnav a.icon {
    background: transparent;
    display: block;
    position: absolute;
    right: 0;
    top: 14px;
    border: 0;
  }
}

@media (max-width: 768px) {
  .topnav a.icon {
    top: -1px;
    right: 0;
  }
}

.icon i::before {
  font-size: 1.8rem;
}

@media (max-width: 991px) {
  #scrim {
    display: none;
    width: 100vw;
    height: 200vh;
    background: var(--color_eighth-opacity-80);
    opacity: .5;
    position: fixed;
    top: 0;
    left: 0;
    animation-name: scrim;
    animation-duration: .3s;
  }
}

@keyframes scrim {
  from {
    opacity: 0;
  }

  to {
    opacity: .5;
  }
}

ul.icon-nav {
  position: absolute;
  right: 48px;
  top: 34px;
  margin: 0;
}

@media (max-width: 1200px) {
  ul.icon-nav {
    right: 20px;
  }
}

@media (max-width: 991px) {
  ul.icon-nav {
    right: 62px;
  }
}

@media (max-width: 768px) {
  ul.icon-nav {
    top: 22px;
  }
}

ul.icon-nav li {
  display: inline-block;
}

ul.icon-nav li img {
  width: 32px;
  height: 32px;
}

.hero-symbbol {
  position: absolute;
  right: 3vw;
  top: 3vw;
  width: 12vw;
}

@media (max-width: 991px) {
  .hero-symbbol {
    right: 40px;
    top: -45px;
    width: 120px;
  }
}

@media (max-width: 768px) {
  .hero-symbbol {
    right: 22px;
    top: -30px;
    width: 80px;
  }
}

@media (max-width: 420px) {
  .hero-symbbol {
    right: 8px;
  }
}

.hero-sub {
  margin: 106px 0 0 0;
}

@media (max-width: 768px) {
  .hero-sub {
    margin: 75px 0 0 0;
  }
}

.hero-sub img {
  object-fit: cover;
  height: 100%;
}

.hero-sub-text {
  display: flex;
  align-items: center;
  padding: 0 30px;
}

@media screen and (max-width: 767px) {
  .hero-sub-text {
    padding: 0 15px;
  }
}

.hero-sub-text h2 {
  width: 70%;
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_1);
  line-height: 3vw;
  margin : 32px 0;
}

@media screen and (max-width: 991px) {
  .hero-sub-text h2 {
    width: 87%;
    line-height: 2.8rem;
  }
}

.breaker {
  background-color: var(--color_second-opacity-90);
  margin: 128px 0;
}

.breaker img {
  width: 120px;
  margin: -50px 0 24px 0;
}

.breaker p {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_5);
  text-align: center;
  line-height: 3.8rem;
  padding: 32px;
}

@media screen and (max-width: 768px) {
  .breaker p {
    line-height: 2.8rem;
  }
}

.breaker-button-wrapper {
  margin: 64px 0 -28px 0;
  position: relative;
  z-index: 1;
}

.outro {
  margin: 128px 0 0 0;
  padding: 0 0 256px 0;
}

.outro img {
  width: 120px;
  margin: 0 0 32px 0;
}

.outro p {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_3);
  margin: 0 0 32px 0;
}

.card-outro {
  margin: 0 0 -44px 0;
}

.card-outro img {
  width: 120px;
  margin: 0 0 32px 0;
}

.card-outro p {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_3);
  margin: 0 0 32px 0;
}

.carousel-control-next, .carousel-control-prev {
  opacity: 1;
  width: 7%;
}

.carousel-control-prev-icon {
  background-image: url("../img/ic_arrow_left.svg");
  background-size: 100%;
  width: 32px;
  height: 32px;
}

.carousel-control-next-icon {
  background-image: url("../img/ic_arrow_right.svg");
  background-size: 100%;
  width: 32px;
  height: 32px;
}

.quote-slider {
  padding: 32px 0;
  background-color: var(--color_seventh);
  overflow: hidden;
}

@media (max-width: 768px) {
  .quote-slider {
    padding: 64px 0;
  }
}

.quote-slider p {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_5);
  line-height: 3.8rem;
  color: var(--color_third);
}

@media (max-width: 768px) {
  .quote-slider p {
    line-height: 2.8rem;
  }
}

.quote-slider .carousel-item {
  padding: 15px;
}

@media (max-width: 1200px) {
  .quote-slider .carousel-item {
    padding: 15px 80px;
  }
}

@media (max-width: 767px) {
  .quote-slider .carousel-item {
    padding: 15px;
  }
}

.quote-slider .carousel-item .caption {
  font-size: var(--font-size_4);
  text-align: right;
  width: 100%;
}

@media (max-width: 768px) {
  .quote-slider .carousel-item .caption {
    font-size: var(--font-size_8);
  }
}

.quote-slider .carousel-inner {
  position: relative;
  max-width: 1140px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .carousel-control-prev {
    left: 0;
    bottom: -24px;
    height: 32px;
    width: 50%;
    top: inherit;
  }
}

@media (max-width: 768px) {
  .carousel-control-next {
    right: 0;
    bottom: -24px;
    height: 32px;
    width: 50%;
    top: inherit;
  }
}

.beforeLined:before {
  content: "";
  display: inline-block;
  width: 140px;
  height: 28px;
  background-image: url("../img/line.svg");
  background-size: 100%;
  position: absolute;
  left: -180px;
}

@media screen and (max-width: 1480px) {
  .beforeLined:before {
    display: none;
  }
}


.imgLined:before {
  content: "";
  display: inline-block;
  width: 140px;
  height: 28px;
  background-image: url("../img/line.svg");
  background-size: 100%;
  position: absolute;
  left: 90px;
}

@media screen and (max-width: 768px) {
  .imgLined:before {
    display: none;
  }
}

.underLined:after {
  content: "";
  display: block;
  width: 70px;
  height: 28px;
  margin: 16px 0 16px 0;
  background-image: url("../img/line-short.svg");
  background-size: 100%;
}

.btn {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
  border-radius: 100px;
  border: 2px solid transparent;
  min-height: 56px;
  min-width: 180px;
  line-height: 52px;
  padding: 0 32px;
  font-size: var(--font-size_2);
}

.btn-primary {
  color: var(--color_third);
  background-color: var(--color_main);
  border-color: var(--color_main);
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary.focus, .btn-primary:focus {
  color: var(--color_main);
  background-color: var(--color_second);
  border-color: var(--color_second);
}

.btn-secondary {
  color: var(--color_main);
  background-color: transparent;
  border-color: var(--color_main);
}

.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle,
.btn-secondary.focus, .btn-secondary:focus {
  color: var(--color_third);
  background-color: var(--color_main);
  border-color: var(--color_main);
}

.content-box h3 {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_6);
  line-height: 2.6rem;
  margin: 0 0 32px 0;
}

@media (max-width: 768px) {
  .content-box h3 {
    line-height: 2.0rem;
  }
}
