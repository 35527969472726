#programs {
  background-color: var(--color_second-opacity-25);
}

.hero-sub {
  background-color: var(--color_second);
}

.content-box {
  padding: 0;
  position: relative;
  background-color: transparent;
}

@media (max-width: 768px) {
  .content-box {
    margin: 64px 0;
  }
}

.content-box-text-wrapper {
  display: flex;
  align-items: center;
}

.content-box-button-wrapper {
  margin-top: -30px;
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 991px) {
  .content-box-button-wrapper {
    margin-top: 0;
  }
}

.content-box-button-wrapper a {
  font-family: var(--font-family_4);
  font-size: var(--font-size_2);
  color: var(--color-main);
  text-decoration: underline;
}

.content-box .breaker {
  margin: 0;
  width: calc(100% + 60px);
}

div#accordion .card {
  transform: scale(1) !important;
  transition: none;
}

.card {
  border-radius: 0;
  border: 0;
}

.card-header:first-child {
  border: 0;
  border-radius: 0;
}

.card-header {
  padding: 0 30px 30px 30px;
  margin: 128px 0 0 0;
  background-color: #fff;
}

.card-header .btn {
  color: var(--color_main);
  text-decoration: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  min-height: inherit;
  min-width: inherit;
  line-height: inherit;
}

.card-body {
  padding: 0;
}

.card-content {
  padding: 0 30px 64px 30px;
  background-color: #fff;
}

.card-body figure {
  width: calc(33.333% - 2px);
  display: inline-block;
}

#accordion .content-box.beforeLined:before {
  left: -195px;
  top: 120px;
}
