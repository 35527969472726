.carousel-wrapper {
  margin: 106px 0 0 0;
}

@media (max-width: 768px) {
  .carousel-wrapper {
    margin: 75px 0 0 0;
  }
}

@media (max-width: 768px) {
  .carousel-inner {
    height: auto;
  }
}

.carousel-item.full-screen {
  background-size: 100%;
}

.carousel-text-wrapper {
  display: flex;
  width: 50%;
  height: 100%;
  align-items: center;
  right: 0;
  position: absolute;
  top: -4vw;
  z-index: 0;
}

@media (max-width: 991px) {
  .carousel-text-wrapper {
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    height: auto;
    position: relative;
    bottom: inherit;
    top: inherit;
    padding: 30px;
    background-color: #fff;
  }
}

@media (max-width: 420px) {
  .carousel-text-wrapper {
    padding: 15px;
  }
}

.text-wrapper-padding {
  width: 65%;
}

@media (max-width: 991px) {
  .text-wrapper-padding {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .text-wrapper-padding {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .carousel-indicators {
    bottom: -70px;
  }
}

.carousel-indicators li {
  background-color: transparent;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  border: 2px solid var(--color_main);
  list-style: none;
  opacity: 1;
}

.carousel-indicators li.active {
  background-color: var(--color_main);
  opacity: 1;
}

@media (max-width: 768px) {
  .carousel-item {
    height: auto;
  }
}

.d-block {
  object-fit: cover;
  height: 100%;
}

@media (max-width: 768px) {
  .d-block {
    height: auto;
    width: 175% !important;
    object-position: 15% 0;
  }
}


.carousel-item .carousel-text {
  font-family: var(--font-family_2_2);
  font-size: var(--font-size_1);
}

@media (max-width: 768px) {
  .carousel-item .carousel-text {
    line-height: 2.8rem;
  }
}

.carousel-item .carousel-text:nth-last-child(1) {
  margin: 0;
}

#carouselHero ol.carousel-indicators {
  display: none;
}

#carouselHero .carousel-control-next,
#carouselHero .carousel-control-prev {
  display: none;
}

@media (max-width: 1024px) {
  .hero-symbbol {
    right: 40px;
    top: 30px;
    width: 120px;
  }
}

@media (max-width: 991px) {
  .hero-symbbol {
    right: 40px;
    width: 120px;
    bottom: -72px;
    top: inherit;
  }
}

@media (max-width: 768px) {
  .hero-symbbol {
    width: 80px;
    bottom: -48px;
    right: 22px;
  }
}

.intro {
  background-color: var(--color_second-opacity-25);
  margin: 0 0 -128px 0;
}

.card {
  border: 0;
  border-radius: 0;
  margin: 0 0 32px 0;
  transform: scale(1);
  transition: transform .3s;
}

.card:hover {
  transform: scale(0.99);
  transition: transform .3s;
}

.card-body.title {
  background-color: var(--color_main);
  text-align: center;
  padding: .5rem 2rem 0 2rem;
}

.card-title {
  color: var(--color_third);
  font-size: var(--font-size_2);
}

.card-body.content {
  background-color: var(--color_second-opacity-50);
  min-height: 300px;
  padding: 2rem;
}

.card-text {
  font-size: var(--font-size_4);
}

.card-body.content a, .card-body.content a:hover {
  font-family: var(--font-family_4);
  font-size: var(--font-size_4);
  color: var(--color_main);
  text-decoration: underline;
}


.video-container {
  background-color: var(--color_third);
}

.video-container h2 {
  margin: 96px 0 64px 0;
}

.gratis {
  margin: 96px 0 128px 0;
}

.gratis .col-box {
  width: 75%;
  background-color: var(--color_fourth);
}

@media screen and (max-width: 1280px) {
  .gratis .col-box {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .gratis .col-box {
    width: 100%;
  }
}

.gratis .col-box-image img {
  object-fit: cover;
  object-position: left;
  width: 100%;
  height: 100%;
}

.gratis .col-box-text {
  padding: 24px 64px 64px 64px;
}

.gratis .col-box-text:before {
  content: "";
  display: block;
  width: 128px;
  height: 128px;
  background-image: url("../img/gratis-badge.svg");
  background-size: 100%;
  position: absolute;
  bottom: 130px;
  left: -80px;
}

@media screen and (max-width: 991px) {
  .gratis .col-box-text:before {
      top: -90px;
      bottom: inherit;
      right: 50px;
      left: inherit;
  }
}

.gratis .btn {
  margin: 24px 0 0 0;
}
