:root {
  --content-width: 100%;

  --font-family_1: "Barlow-Thin";
  --font-family_2: "Barlow-Light";
  --font-family_2_2: "Barlow-LightItalic";
  --font-family_3: "Barlow-Regular";
  --font-family_4: "Barlow-Medium";
  --font-family_4_2: "Barlow-MediumItalic";
  --font-family_5: "Barlow-SemiBold";
  --font-family_6: "Barlow-Bold";
  --font-family_7: "Barlow-Black";

  --font-size_1: 2.1vw;
  --font-size_2: 1.4rem;
  --font-size_3: 2.0rem;
  --font-size_4: 1.2rem;
  --font-size_5: 2.8rem;
  --font-size_6: 1.8rem;
  --font-size_7: 1.6rem;

  --color_main: #652d46;
  --color_second: #ffdf60;
  --color_third: #fff;
  --color_fourth: #f9bd85;
  --color_sixth: #f8dfc4;
  --color_seventh: #ad655f;
  --color_eighth: #000;



  --color_second-opacity-90: #ffec98;
  --color_second-opacity-75: #fff4cf;
  --color_second-opacity-50: #fff5d3;
  --color_second-opacity-25: #fff9e5;
  --color_second-opacity-10: #fffdf7;


  --color_sixth-opacity-10: #fcf2e8;

  --color_eighth-opacity-80: rgba(0, 0, 0, .8);

}

@media (max-width: 991px) {
  :root {
    --font-size_1: 2rem;
  }
}

@media (max-width: 768px) {
  :root {
    --font-size_1: 1.8rem;
    --font-size_2: 1.2rem;
    --font-size_3: 1.6rem;
    --font-size_4: 1.2rem;
    --font-size_5: 2rem;
    --font-size_6: 1.4rem;
    --font-size_8: 1rem;
  }
}

@font-face {
  font-family: "Barlow-Black";
  src: url("../fonts/Barlow-Black.woff");
}

@font-face {
  font-family: "Barlow-Bold";
  src: url("../fonts/Barlow-Bold.woff");
}

@font-face {
  font-family: "Barlow-SemiBold";
  src: url("../fonts/Barlow-SemiBold.woff");
}

@font-face {
  font-family: "Barlow-Medium";
  src: url("../fonts/Barlow-Medium.woff");
}

@font-face {
  font-family: "Barlow-MediumItalic";
  src: url("../fonts/Barlow-MediumItalic.woff");
}

@font-face {
  font-family: "Barlow-Regular";
  src: url("../fonts/Barlow-Regular.woff");
}

@font-face {
  font-family: "Barlow-RegularItalic";
  src: url("../fonts/Barlow-RegularItalic.woff");
}

@font-face {
  font-family: "Barlow-Light";
  src: url("../fonts/Barlow-Light.woff");
}


@font-face {
  font-family: "Barlow-LightItalic";
  src: url("../fonts/Barlow-LightItalic.woff");
}

@font-face {
  font-family: "Barlow-Thin";
  src: url("../fonts/Barlow-Thin.woff");
}

*,
*:after,
*:before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

body {
  font-family: "Barlow-Regular";
  color: var(--color_main);
  background-color: var(--color_second-opacity-10);
  overflow-x: hidden;
  overflow-y: auto;
}

li {
  list-style: none;
}

p {
  font-size: var(--font-size_2);
}

a {
  color: currentColor;
  text-decoration: none;
}

h2, .h2 {
  font-family: var(--font-family_4);
  font-size: var(--font-size_3);
  margin: 32px 0 32px 0;
}

h3, .h3 {
  font-family: var(--font-family_4);
  font-size: var(--font-size_3);
  margin: 32px 0 0 0;
}

strong, b {
  font-weight: 100;
  font-family: var(--font-family_4);
}

ul {
  margin: 64px 0;
}

li {
  font-family: var(--font-family_3);
  font-size: var(--font-size_2);
  margin: 0 0 16px 12px;
  list-style-type: "- ";
}

figure {
  margin: 0 0 1rem;
  width: calc(33.333% - 2px);
  display: inline-block;
}

img {
  width: 100%;
}

.page {
  padding: 0;
}
.page > * {
  max-width: var(--content-width);
  margin: 0 auto;
}

.header {
  height: 106px;
  width: 100%;
  background: #fff;
  padding: 16px 32px;
  position: fixed;
  top: 0;
  z-index: 2;
}

@media (max-width: 1024px) {
  .header {
    padding: 16px 16px;
  }
}

@media (max-width: 768px) {
  .header {
    height: 75px;
    padding: 16px 16px;
  }
}

.header a {
  position: relative;
  text-transform: uppercase;
  font-size: var(--font-size_4);
  padding: .5rem 0;
}

.header .logo {
  display: block;
  margin-bottom: 1.5rem;
  padding: .6rem 0;
  width: 220px;
}

@media (max-width: 768px) {
  .header .logo {
    width: 170px;
    padding: 0;
  }
}

main {
  min-height: calc(100vh - 10rem);
}

.intro {
  padding: 10vh 0;
}

@media (max-width: 768px) {
  .intro {
    padding: 10vh 15px;
  }
}

.intro h1 {
  position: relative;
  margin-bottom: 1rem;
  font-weight: 900;
  font-size: calc(1vw + 2rem);
  z-index: 1;
}

.tags {
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.075em;
  font-size: .75rem;
  font-weight: 600;
}

.text {
  line-height: 2.2em;
  margin: 200px 0 0 0;
}

.text p,
.text figure,
.text ul,
.text ol {
  margin-bottom: 1.5em;
}

.text h2 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.text h3 {
  margin-bottom: 0.8rem;
}

.text > *:first-child {
  margin-top: 0;
}

.text a {
  position: relative;
  white-space: nowrap;
  font-weight: 500;
  z-index: 1;
  display: inline-block;
}

.text figure {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.text img {
  width: 100%;
}

video {
  background: #000;
  width: 80%;
  height: 80%;
  outline: none;
}

@media (max-width: 1024px) {
  video {
    width: 100%;
    height: 100%;
  }
}

.footer {
  max-width: var(--content-width);
  margin: 0 auto;
  padding: 32px 16px;
  line-height: 1.5em;
  background-color: var(--color_main);
}

.footer .logo {
  display: block;
  margin-bottom: 1.5rem;
  padding: .6rem 0;
  width: 220px;
}

@media (max-width: 768px) {
  .footer .logo {
      width: 170px;
  }
}

.footer a {
  display: inline-block;
  font-size: .875rem;
  color: var(--color_third);
}
.footer > a {
  margin-bottom: 1.5rem;
  border-top: 2px solid #000;
  width: 16.5rem;
  padding-top: .5rem;
  color: var(--color_third);
}

.social a {
  margin: 0 .75rem;
  padding: .5rem 1rem;
  border: 2px solid #000;
  width: 7.5rem;
  color: var(--color_third);
}
.social a:hover {
  background: #000;
}

.footer-item p {
  font-size: var(--font-size_8);
  color: var(--color_third);
  margin: 0 0 8px 0;
}

.footer>a, .footer a {
  color: var(--color_third);
  font-size: var(--font-size_8);
}

.footer-item label {
  display: block;
  font-size: var(--font-size_8);
  text-align: left;
  color: var(--color_third);
}

@media (max-width: 768px) {
  .footer-wrapper {
    border-bottom: 2px solid rgba(0, 0, 0, .2);
  }
}

@media (max-width: 768px) {
  .footer-wrapper:nth-last-child(1) {
    border-bottom: 0;
  }
}

.form-group.website {
  position: fixed;
  top: 99999px;
  left: 99999px;
}

.modal-body p, .modal-body a {
  font-size: 1rem;
}



#mc_embed_signup form {
  padding: 0 !important;
}

#mc_embed_signup .clear {
  clear: none !important;
  display: flex !important;
  justify-content: flex-end !important;
  margin: 0 !important;
}

#mc_embed_signup input.email {
  border: 2px solid var(--color_second-opacity-50) !important;
  background: transparent !important;
  border-top-left-radius: 100px!important;
  border-bottom-left-radius: 100px!important;
  height: 44px !important;
  width: 70% !important;
  outline: 0 !important;
  padding: 0 16px !important;
  margin: 0 0 8px 0 !important;
  color: var(--color_third) !important;
}

#mc_embed_signup input.button {
  display: block !important;
  width: 30% !important;
  height: 44px !important;
  background: var(--color_second-opacity-50) !important;
  color: var(--color_main) !important;
  margin: -52px 0 0 0 !important;
  min-width: 90px !important;
  border-top-right-radius: 100px!important;
  border-bottom-right-radius: 100px!important;
}

::placeholder {
  color: var(--color_third) !important;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: var(--color_third) !important;
}

::-ms-input-placeholder {
  color: var(--color_third) !important;
}
