#free-offer {
  background-color: var(--color_sixth-opacity-10);
}
.hero-sub {
  background-color: var(--color_sixth);
}

.offer-box.even {
  background-color: var(--color_sixth);
}

.offer-box.odd {
  background-color: var(--color_sixth-opacity-10);
}

.offer-box img {
  width: 80%;
  margin: 48px 0 0 0;
}

@media (max-width: 512px) {
  .offer-box img {
    width: 80%;
    margin: 0;
  }
}

.offer-box .img-wrapper > div {
  position: relative;
}

.offer-box .img-wrapper > div:before {
  content: "";
  display: block;
  width: 96px;
  height: 96px;
  background-image: url("../img/gratis-badge.svg");
  background-size: 100%;
  position: absolute;
  right: 0;
  bottom: -24px;
}

@media (max-width: 991px) {
  .offer-box .img-wrapper > div:before {
    width: 150px;
    height: 150px;
    right: initial;
    bottom: 32px;
    right: 0;
  }
}

@media (max-width: 512px) {
  .offer-box .img-wrapper > div:before {
    width: 100px;
    height: 100px;
    bottom: 24px;
  }
}

.offer-box-btn-wrapper {
  width: 100%;
  text-align: right;
  bottom: 0;
  margin: 48px 0;
}

@media (max-width: 991px) {
  .offer-box-btn-wrapper {
    width: 100%;
    text-align: center;
    position: static;
    right: inherit;
    bottom: inherit;
    margin: 48px 0 32px 0;
  }
}
